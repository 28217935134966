<template>
  <!-- Video组件 -->
  <div id="common-video" class="h-100">
    <div :class="{ isShow: control }" class="h-100">
      <video
        ref="myVideo"
        :poster="poster"
        :src="src"
        :controls="controls"
        oncontextmenu="return false"
        @timeupdate="timeupdate"
        @canplay="getDuration"
        controlslist="nodownload"
        class="video-box"
        id="video-box"
        :autoplay="autoplay"
      ></video>
      <img
        src="@/assets/img/playbtn.png"
        alt=""
        @click="operateVideo"
        class="pointer operate-btn"
        :class="{ 'fade-out': videoState }"
      />
    </div>
  </div>
</template>

<script>
import req from "../utils/req";
export default {
  name: "videoplay",
  data() {
    return {
      videoState: false, // 视频播放状态
      // 学时
      studyTime: {
        currentTime: 0, // 当前已学时长
        duration: 0, // 总时长
      },
      timer: {}, // 定时器
      pauseTimer: {}, // 暂停定时器
    };
  },
  /**
   * @param poster 展示图
   * @param src 视频资源
   * @param controls 是否显示控件
   * @param control 控件控制
   * @param videoData {currentTime:0,//学时；duration:0,//总时长}视频基础数据
   * 1、使用 isShow 属性配合 css 样式动态展示视频滚动条
   * 2、使用 video 标签的 οncοntextmenu=“return false” 属性来实现禁止下载
   * 3、使用 video 标签的 @timeupdate=“timeupdate” 方法来实现视频播放进度监听
   * 4、使用 vue 的 ref 属性为 video 标签绑定监听事件，来实现其他功能，如时长统计、延迟提示、动态显示图标播放按钮等功能。
   */
  props: {
    film_id: {
      type: [Number, String],
      required: false,
      default: "",
    },
    autoplay: {
      type: Boolean,
      required: false,
      default: true,
    },
    poster: {
      type: String,
      required: false,
      default: "",
    },
    src: {
      type: String,
      required: true,
    },
    controls: {
      type: Boolean,
      required: false,
      default: true,
    },
    control: {
      type: Boolean,
      required: false,
      default: false,
    },
    videoData: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    // 监听视频播放
    this.$refs.myVideo.addEventListener("play", () => {
      console.log("video is playing");
      this.openTimer();
      this.videoState = true;
    });
    // 监听视频暂停
    this.$refs.myVideo.addEventListener("pause", () => {
      console.log("video is stop");
      this.closeTimer();
      this.videoState = false;
    });
  },
  methods: {
    getDuration() {
      let s = this.$refs.myVideo.duration; //获取视频的总时长
      console.log(s + "=========获取视频的总时长");
      // this.videoData.duration = s
    },
    // 开启定时器
    openTimer() {
      this.timer = setInterval(() => {
        this.$emit("videoStudyTime", this.studyTime);
      }, 5000);
    },
    // 关闭定时器
    closeTimer() {
      clearInterval(this.timer);
      this.$emit("videoStudyTime", this.studyTime);
    },
    // 开启暂停定时器
    openPauseTimer() {
      this.pauseTimer = setInterval(() => {
        this.hintOperate();
      }, 600000);
    },
    // 关闭暂停定时器
    closePauseTimer() {
      clearInterval(this.pauseTimer);
    },
    // 提示操作
    hintOperate() {
      this.operateVideo();

      this.$alert("请点击确认继续观看", "提示", {
        confirmButtonText: "确定",
        confirmButtonClass: "hint-btn",
        showClose: false,
        callback: (action) => {
          console.log(action);
          this.$refs.myVideo.currentTime = this.videoData.currentTime;
          this.operateVideo();
          this.openPauseTimer();
        },
      });
    },
    // 获取当前播放位置
    timeupdate(e) {
      // let s = this.$refs.myVideo.currentTime; //获取当前播放时间
      // console.log(s + "=======获取当前播放时间");
      this.studyTime.currentTime = e.target.currentTime;
      this.studyTime.duration = e.target.duration ? e.target.duration : 0;
      this.$emit('timeupdate',e)
      // this.addLog();
    },
    
    // 操作视频播放、暂停
    operateVideo() {
      if (!this.src) {
        this.$message({ message: "暂无视频资源，请查看其他视频！" });
        return false;
      }
      if (this.$refs.myVideo.paused) {
        this.$refs.myVideo.play();
        this.videoState = true;
      } else {
        this.$refs.myVideo.pause();
        this.videoState = false;
      }
    },
  },
  watch: {
    // 监听操作
    videoData(val, oldVal) {
      console.log("是", val);
      console.log("否", oldVal);
      const { currentTime, duration } = val;
      if (currentTime && duration && currentTime < duration) {
        this.hintOperate();
      }
    },
  },
};
</script>

<style lang="scss">
.h-100 {
  height: 100%;
}
#common-video {
  position: relative;
  .video-box {
    box-sizing: border-box;
    border: 0;
    display: block;
    width: 100%;
    height: 100%;
    outline: none !important;
  }

  video {
    object-fit: fill;
  }
  .isShow {
    //进度条
    video::-webkit-media-controls-timeline {
      display: none;
    }
  }
  video::-webkit-media-controls-play-button {
    visibility: hidden;
  }
  .operate-btn {
    display: block;
    width: 6.625rem;
    height: 6.625rem;
    position: absolute;
    top: calc(50% - 5rem);
    left: calc(50% - 1.875rem);
  }
  .operate-btn:hover {
    opacity: 0.8;
  }
  .fade-out {
    opacity: 0;
  }
}
</style>
